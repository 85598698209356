.modal {
  white-space: pre-line;
}

.modal-title {
  color: #00b4e2;
  font-weight: 600;
}

.modal-header,
.modal-footer {
  border: 0;
}

.modal-header {
  padding-bottom: 0;
  position: relative;
}

.modal-link {
  color: #00b4e2;
  border-color: #00b4e2;
}

.btn-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
