:root {
  --primary-blue: #00b4e2;
}

.container {
  max-width: 1024px;
}

.container-fluid {
  font-family: 'Poppins', sans-serif;
  max-width: 100rem;
}

/* Splash Page --------------------------------- */

.heading {
  font-size: 3rem;
  margin-top: 1.875rem;
  margin-bottom: -0.875rem;
}

.typed-text {
  color: var(--primary-blue);
  font-weight: 700;
  font-size: 4.3rem;
  padding-left: 1.875rem;
}

/* Project Container --------------------------- */
.project-container {
  max-width: 100rem;
  background-color: gray;
}

/* Project Component --------------------------- */
.project-image {
  width: 100%;
  height: auto;
}

button {
  all: unset;
  cursor: pointer;
}

/* Contact Page -------------------------------- */
.form-container {
  margin-top: 7.5rem;
  max-width: 35rem;
}

/* scroll room for form on mobile */
@media (max-width: 480px) {
  .mobile {
    padding-bottom: 27vh;
  }
}

.contact-header {
  text-align: center;
  color: var(--primary-blue);
  font-weight: 700;
}

.contact {
  text-align: center;
}

.form-control {
  border: 1px solid #00b4e244;
}

textarea.form-control {
  min-height: calc(5em + (0.75rem + 2px));
}

.submit-button {
  border-color: var(--primary-blue);
  color: var(--primary-blue);
}

.error-msg {
  color: red;
}

.social-icons li {
  display: inline-block;
  padding-right: 1rem;
}
