/* Arise and be doing, and the LORD be with thee. I Chron 22:16 */

.navbar {
  padding: 1rem 0.625rem;
  background-color: white;
  opacity: 0.99;
}

#cdc {
  font-weight: 500;
  margin-bottom: 0;
}

/* nav links */
nav a.nav-link {
  cursor: pointer;
}

.nav-item {
  text-align: center;
}

.navbar-light .navbar-toggler {
  border-color: white;
}

/* Animated Hamburger */
.navbar-toggler span {
  display: block;
  background-color: #212529;
  height: 0.1875rem;
  width: 1.5625rem;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.15s ease-out;
  transform-origin: center left;
}

.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg);
}

.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg);
}

.navbar-toggler span:nth-child(2) {
  opacity: 1;
}
.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg);
}

@media screen and (max-width: 576px) {
  #cdc {
    padding-left: 0.75rem;
  }
}
